<template>

  <div>
    <div class="">
      <b-table-simple
        ref="teamsTable"
        class="position-relative has_padding"
        responsive
        hover
        caption-top
      >
        <caption>Monthly Roster {{ selectedMonth }}</caption>
        <b-thead head-variant="light">
          <b-tr class="group__tag">
            <b-th />
            <b-th
              v-for="(remark, index) in rosterRemarks"
              :key="index"
              class="group__tag_info text-center"
              :colspan="remark.colspan"
              :style="{ 'background': remark.color + '!important' }"
            >
              <span>{{ remark.message }}</span>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>
              Staff Name
            </b-th>
            <b-th
              v-for="(monthlyGrouping, index) in dateArray"
              :key="index"
              class="next_prev_block"
            >
              <b-button
                v-show="index == 0"
                variant="flat-primary"
                :disabled="currentWeek == 1"
                class="prev__week"
                @click="moveToPrevious()"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
              </b-button>
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'ddd') }} {{ formatLocalMonthlyGroupingDate(monthlyGrouping.monthDate) }}</span>
              <br>
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'D-MMM') }}</span>

              <b-button
                v-show="index == (dateArray.length ? dateArray.length - 1 : 1)"
                variant="flat-primary"
                :disabled="currentWeek == 5 || (currentWeek == 4 && monthlyGroupingValues.length < 29)"
                class="next__week"
                @click="moveToNext()"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  class="mr-50"
                  size="16"
                />
              </b-button>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(team, key) in searchUser"
            :key="key"
          >
            <b-td
              v-if="team.users.length"
              class="no-padding-td"
              :colspan="dateArray.length ? dateArray.length+1 : 1"
            >
              <b-table-simple
                ref="relatedUserTable"
                class="position-relative"
                responsive
                hover
              >
                <b-thead>
                  <b-tr>
                    <b-th :colspan="dateArray.length ? dateArray.length : 1">
                      {{ team.name }}
                    </b-th>
                    <b-th class="text-right">
                      <div class="action_button">
                        <b-button
                          v-show="key != 0"
                          variant="flat-primary"
                          @click="moveTeam(key, key-1)"
                        >
                          <feather-icon
                            icon="ArrowUpIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-show="key != (teams.length - 1)"
                          variant="flat-primary"
                          @click="moveTeam(key, key+1)"
                        >
                          <feather-icon
                            icon="ArrowDownIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody class="white-body-background">
                  <b-tr
                    v-for="(user, index) in team.users"
                    :key="index"
                  >
                    <b-td>
                      {{ user.name }}
                    </b-td>
                    <!-- <b-td
                      v-for="(monthlyGrouping, dateIndex) in dateArray"
                      :key="dateIndex"
                      class="td-no-padding-right"
                      :style="{ 'background-color': resolveDateBGValue(key, index, monthlyGrouping) }"
                      @dblclick="setDoubleClickEdit(key, index, dateIndex)"
                    >
                      <span>{{ resolveDateValue(key, index, monthlyGrouping) }}</span>
                      <b-button
                        v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (doubleClickEnable[key] ? (doubleClickEnable[key][index] ? (doubleClickEnable[key][index][dateIndex] ? doubleClickEnable[key][index][dateIndex] : false ) : false ) : false)"
                        variant="flat-primary"
                        @click="enableEditing(monthlyGrouping, user._id, team._id)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="mr-50"
                          size="16"
                        />
                      </b-button>
                    </b-td> -->

                    <b-td
                      v-for="(monthlyGrouping, dateIndex) in dateArray"
                      :key="dateIndex"
                      class="td-no-padding-right hover-td"
                      :style="{ 'background-color': resolveDateBGValueNew((statusUpdatedData[key][index] ? statusUpdatedData[key][index] : []), monthlyGrouping.monthDate) }"
                    >
                      <div class="">
                        <span>{{ resolveDateValueNew((statusUpdatedData[key][index] ? statusUpdatedData[key][index] : []), monthlyGrouping.monthDate) }}</span>
                        <b-button
                          v-if="canViewThisAction('create', 'MonthlyGroupingStatus')"
                          variant="flat-primary"
                          class="edit-icon no-padding-icon-button"
                          @click="enableEditing(monthlyGrouping, user._id, team._id)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class=""
                            size="14"
                          />
                        </b-button>
                        <b-button
                          v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (!['', 'OFF', 'REST'].includes(resolveDateValueNew((statusUpdatedData[key][index] ? statusUpdatedData[key][index] : []), monthlyGrouping.monthDate)))"
                          variant="flat-danger"
                          class="edit-icon no-padding-icon-button"
                          @click="deleteStatusValue(monthlyGrouping, user._id, team._id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class=""
                            size="14"
                          />
                        </b-button>
                        <b-button
                          v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (['OFF', 'REST'].includes(resolveDateValueNew((statusUpdatedData[key][index] ? statusUpdatedData[key][index] : []), monthlyGrouping.monthDate)))"
                          variant="flat-danger"
                          class="edit-icon no-padding-icon-button"
                          @click="deleteOffStatusValue(monthlyGrouping, user._id, team._id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class=""
                            size="14"
                          />
                        </b-button>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- modal -->
    <b-modal
      id="modal-update-team"
      ref="update-team-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add Status Type"
      @ok="submitStatusForm"
    >
      <b-form @submit.prevent="submitStatusForm">
        <validation-observer
          ref="statusForm"
        >
          <b-form-group
            label="Status Type*"
            label-for="h-status-type"
          >
            <validation-provider
              name="Status Type"
              vid="statusType"
              rules="required"
            >
              <select
                id="h-status-type"
                class="form-control"
                @change="setStatusValue"
              >
                <option
                  disabled
                  value=""
                  selected
                >
                  Choose a Status....
                </option>
                <option
                  v-for="(type, ind) in statuses"
                  :key="ind"
                  :value="type._id"
                >
                  {{ type.name }}
                </option>
              </select>
            </validation-provider>
            <small
              v-if="statusTypeValidation"
              class="text-danger"
            >
              {{ statusTypeError }}
            </small>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import moment from 'moment'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()
let currentUser = ''
// let isRest = true
let userOffDays = []

export default {
  components: {
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BModal,
    BForm,
    BFormGroup,

    // vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    annualGroupingValues: {
      type: Array,
      required: true,
    },
    rosterMonths: {
      type: Array,
      required: true,
    },
    monthlyGroupingValues: {
      type: Array,
      required: true,
    },
    statusUpdatedData: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    yearValues: {
      type: Array,
      required: true,
    },
    rosterGroups: {
      type: Array,
      required: true,
    },
    monthlyGroupingStatuses: {
      type: Array,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
    monthlyRemarks: {
      type: Array,
      required: true,
    },
    selectedMonth: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    selectedStatusTypeId: {
      type: String,
      default: '',
    },
    selectedId: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      canViewThisAction,
      showSearchBar: false,
      statusType: '',
      selectedMonthlyGrouping: '',
      selectedUserId: '',
      selectedTeamId: '',
      monthlyStatuses: [],
      dateArray: [],
      currentWeek: 1,
      rosterRemarks: [],
      doubleClickEnable: [],
      required,
      statusTypeValidation: false,
      statusTypeError: '',
    }
  },
  computed: {
    groupingStatuses() {
      if (this.monthlyStatuses.length) {
        return this.monthlyStatuses
      }
      return this.monthlyGroupingStatuses
    },
    searchUser() {
      return this.teams.map(team => {
        const data = { ...team }
        data.users = data.users.filter(user => user.name.toLowerCase().match(this.searchQuery.toLowerCase()))
        return data
      })
    },
  },
  watch: {
    monthlyGroupingValues: {
      immediate: true,
      handler(val) {
        this.dateArray = val.slice(0, 7)
        this.resolveRosterRemarks()
      },
    },
    monthlyRemarks: {
      immediate: true,
      handler() {
        this.resolveRosterRemarks()
      },
    },
  },
  created() {
    this.dateArray = this.monthlyGroupingValues.slice(0, 7)
    this.resolveRosterRemarks()

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < this.teams.length; i++) {
    //   const teamArray = []
    //   // eslint-disable-next-line no-plusplus
    //   for (let j = 0; j < this.teams[i].users.length; j++) {
    //     const userArray = []
    //     // eslint-disable-next-line no-plusplus
    //     for (let k = 0; k < this.monthlyGroupingValues.length; k++) {
    //       userArray[k] = false
    //     }
    //     teamArray[j] = userArray
    //   }
    //   this.doubleClickEnable[i] = teamArray
    // }
    if (this.selectedId) {
      this.changeMonthlyGrouping(this.selectedId)
    }
  },
  methods: {
    setStatusValue(event) {
      this.statusType = event.target.value
    },
    formatMonthlyGroupingDate(date, format) {
      return moment(date).format(format)
    },
    formatLocalMonthlyGroupingDate(date) {
      return moment(date).locale('zh_cn').format('dd')
    },

    // eslint-disable-next-line no-unused-vars
    resolveGroupName(user, month) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month])
        if (val) {
          return val.abbreviation
        }
      }

      return 'N/A'
    },

    resolveMonthID() {
      const month = this.rosterMonths.find(o => o.name === this.selectedMonth)
      if (month) {
        return month._id
      }

      return '0'
    },

    resolveGroupStatus(user, month, day) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month.toLowerCase()])
        if (val) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < val.dayOffDays.length; i++) {
            // eslint-disable-next-line no-plusplus
            if (val.dayOffDays[i] === day) {
              return val._id
            }
          }
        }
      }

      return null
    },
    changeMonthlyGrouping(month) {
      this.$http.get(`operation/monthly-grouping/${month}/status-type`)
        .then(response => {
          // this.monthlyStatuses = response.data.statuses || []
          this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
          this.$emit('emitDateValuesToPlot')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    // setDoubleClickEdit(teamIndex, userIndex, dateIndex) {
    //   // this.$set(this.doubleClickEnable[teamIndex][userIndex], dateIndex, true)
    //   this.doubleClickEnable[teamIndex][userIndex].splice(dateIndex, 1, true)
    //   this.doubleClickEnable[teamIndex].splice(userIndex, 1, this.doubleClickEnable[teamIndex][userIndex])
    //   this.doubleClickEnable.splice(teamIndex, 1, this.doubleClickEnable[teamIndex])
    // },
    resolveDateValueAgain(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      const user = team.users[userIndex]

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id && o.team === team._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            return status.abbreviation
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) return 'OFF'
          }
        }
      }
      return ''
    },

    resolveDateValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      const user = team.users[userIndex]
      let state = ''
      let shouldChangeState = true

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id && o.team === team._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            state = status.abbreviation
            shouldChangeState = false
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) {
              if (currentUser === '') {
                currentUser = user._id
                userOffDays = []
                this.monthlyGroupingValues.forEach(monthlyGroup => {
                  const dday = moment(monthlyGroup.monthDate).format('ddd')
                  if (rosterGroup.dayOffDays.includes(dday)) {
                    // const object = {}
                    // object.date = monthlyGroup.monthDate
                    userOffDays.push(monthlyGroup.monthDate)
                  }
                })
              }

              if (currentUser === user._id) {
                const foundIndex = userOffDays.findIndex(offday => offday === monthlyGrouping.monthDate)
                if (foundIndex === -1) {
                  if (shouldChangeState) {
                    state = ''
                  }
                }

                if (foundIndex === 0 || (foundIndex % 2) === 0) {
                  if (shouldChangeState) {
                    state = 'OFF'
                  }
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (shouldChangeState) {
                    state = 'REST'
                  }
                }
              } else {
                currentUser = user._id
                userOffDays = []
                this.monthlyGroupingValues.forEach(monthlyGroup => {
                  const dday = moment(monthlyGroup.monthDate).format('ddd')
                  if (rosterGroup.dayOffDays.includes(dday)) {
                    // const object = {}
                    // object.date = monthlyGroup.monthDate
                    userOffDays.push(monthlyGroup.monthDate)
                  }
                })
                const foundIndex = userOffDays.findIndex(offday => offday === monthlyGrouping.monthDate)
                if (foundIndex === -1) {
                  if (shouldChangeState) {
                    state = ''
                  }
                }

                if (foundIndex === 0 || (foundIndex % 2) === 0) {
                  if (shouldChangeState) {
                    state = 'OFF'
                  }
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (shouldChangeState) {
                    state = 'REST'
                  }
                }
              }
            }
          }
        }
      }
      return state
    },

    deleteOffStatusValue(monthlyGrouping, userId, teamId) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'This will remove the off/rest from this date.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('user', userId)
            formData.append('team', teamId)
            formData.append('rosterMonth', monthlyGrouping.rosterMonth)
            formData.append('date', monthlyGrouping.monthDate)
            this.$http.patch('operation/roster-months/remove/group-off-day', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                // this.monthlyStatuses = response.data.statuses || []
                this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
                this.$emit('emitDateValuesToPlot')
                this.remark = ''
                this.dates = []
                this.color = ''
                this.$swal({
                  title: 'Roster Off Status Removed!',
                  html: 'The Roster Status has been removed',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    resolveUserOffDays(group) {
      userOffDays = []
      this.monthlyGroupingValues.forEach(monthlyGrouping => {
        const day = moment(monthlyGrouping.monthDate).format('ddd')
        if (group.dayOffDays.includes(day)) {
          // const object = {}
          // object.date = monthlyGrouping.monthDate
          userOffDays.push(monthlyGrouping.monthDate)
        }
      })
    },
    resolveDateBGValueNew(data, date) {
      // eslint-disable-next-line eqeqeq
      const found = data.find(o => o.date == date)
      if (found) {
        return found.data.color
      }
      return ''
    },
    resolveDateValueNew(data, date) {
      // eslint-disable-next-line eqeqeq
      const found = data.find(o => o.date == date)
      if (found) {
        return found.data.state
      }
      return ''
    },

    resolveDateBGValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      const user = team.users[userIndex]

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status._id === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id && o.team === team._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            return status.color
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) return '#F5BC16'
          }
        }
      }
      return ''
    },

    enableEditing(monthlyGrouping, userId, teamId) {
      this.selectedMonthlyGrouping = monthlyGrouping
      this.selectedUserId = userId
      this.selectedTeamId = teamId
      this.$refs['update-team-modal'].show()
    },

    deleteStatusValue(monthlyGrouping, userId, teamId) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'This will remove the status from this date.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('user', userId)
            formData.append('team', teamId)
            formData.append('rosterMonth', monthlyGrouping.rosterMonth)
            formData.append('date', monthlyGrouping.monthDate)
            this.$http.patch('operation/roster-months/remove/status-type', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                // this.monthlyStatuses = response.data.statuses || []
                this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
                this.$emit('emitDateValuesToPlot')
                this.remark = ''
                this.dates = []
                this.color = ''
                this.$swal({
                  title: 'Roster Status Removed!',
                  html: 'The Roster Status has been removed',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    submitStatusForm() {
      this.$refs.statusForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.statusType)
          formData.append('user', this.selectedUserId)
          formData.append('team', this.selectedTeamId)
          formData.append('rosterMonth', this.selectedMonthlyGrouping.rosterMonth)
          formData.append('date', this.selectedMonthlyGrouping.monthDate)
          this.$http.patch('operation/roster-months/update/status-type', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              // this.monthlyStatuses = response.data.statuses || []
              this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
              this.$emit('emitDateValuesToPlot')
              this.remark = ''
              this.dates = []
              this.color = ''
              this.$swal({
                title: 'Roster Status Updated!',
                html: response.data.message,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusTypeError = validationError.msg
                    this.statusTypeValidation = true
                  }
                })

                this.$refs['update-team-modal'].toggle('#toggle-btn')

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          if (this.statusType === '') {
            this.statusTypeError = 'Status Type is Required'
            this.statusTypeValidation = true
          } else {
            this.statusTypeValidation = false
          }
          this.$refs['update-team-modal'].toggle('#toggle-btn')
        }
      })
    },

    resolveRosterRemarks() {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const remarksArray = []
      let includeConsecutiveCount = 1
      let excludeConsecutiveCount = 1
      const remarksDates = []
      this.monthlyRemarks.forEach(value => {
        value.applicableDates.forEach(date => {
          remarksDates.push(moment(date).utcOffset(timezone).format('YYYY-MM-DD'))
        })
      })

      this.dateArray.forEach((obj, key) => {
        if (remarksDates.includes(obj.monthDate)) {
          const foundRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(obj.monthDate), 'day')))
          this.dateArray[key] = { ...this.dateArray[key], color: foundRemark.color }
          if (key < (this.dateArray.length - 1) && remarksDates.includes(this.dateArray[key + 1].monthDate)) {
            const nextRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(this.dateArray[key + 1].monthDate), 'day')))

            if (foundRemark._id === nextRemark._id) {
              includeConsecutiveCount += 1
            } else {
              const thObj = {}
              thObj.message = foundRemark.message
              thObj.color = foundRemark.color
              thObj.colspan = includeConsecutiveCount
              remarksArray.push(thObj)

              includeConsecutiveCount = 1
            }
          } else {
            const thObj = {}
            thObj.message = foundRemark.message
            thObj.color = foundRemark.color
            thObj.colspan = includeConsecutiveCount
            remarksArray.push(thObj)

            includeConsecutiveCount = 1
          }
        } else {
          this.dateArray[key] = { ...this.dateArray[key], color: '' }
          // eslint-disable-next-line no-lonely-if
          if (key < (this.dateArray.length - 1) && remarksDates.includes(this.dateArray[key + 1].monthDate)) {
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else if (key === (this.dateArray.length - 1)) {
            // excludeConsecutiveCount += 1
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else {
            excludeConsecutiveCount += 1
          }
        }
      })
      this.rosterRemarks = remarksArray
    },

    moveToPrevious() {
      if (this.currentWeek > 1) {
        this.currentWeek -= 1
        this.sliceMonthArray()
      }
    },

    moveToNext() {
      if (this.currentWeek < 5 && this.monthlyGroupingValues.length >= 28) {
        this.currentWeek += 1
        this.sliceMonthArray()
      }
    },

    sliceMonthArray() {
      switch (this.currentWeek) {
        case 1:
          this.dateArray = this.monthlyGroupingValues.slice(0, 7)
          break
        case 2:
          this.dateArray = this.monthlyGroupingValues.slice(7, 14)
          break
        case 3:
          this.dateArray = this.monthlyGroupingValues.slice(14, 21)
          break
        case 4:
          this.dateArray = this.monthlyGroupingValues.slice(21, 28)
          break
        case 5:
          this.dateArray = this.monthlyGroupingValues.slice(28, this.monthlyGroupingValues.length)
          break
        default:
          this.dateArray = this.monthlyGroupingValues.slice(0, 7)
      }
      this.resolveRosterRemarks()
    },

    moveTeam(from, to) {
      this.teams.move(from, to)

      const teamPriorityArray = []
      this.teams.forEach(team => {
        teamPriorityArray.push(team._id)
      })
      const formData = new FormData()

      formData.append('rosterMonth', this.selectedId)
      formData.append('teamPriority', JSON.stringify(teamPriorityArray))

      this.$http.patch('operation/roster-months/update/team-priority', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  table td.no-padding-td {
    padding: 12px 0px !important
  }
  table td.td-no-padding-right {
    padding: 12px 8px !important
  }
  .white-body-background {
    background-color: white;
  }
  .v-select {
    width: 95px;
  }
</style>
