<template>

  <div>
    <b-row
      class="content-header md-roster-view"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Monthly Roster
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="3"
        cols="12"
      >
        <b-button
          v-if="canViewThisAction('create', 'MonthlyGroupingStatus')"
          v-b-modal.modal-create-bulk-status
          variant="success"
          @click="updateFlatPickrConfig"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Update Multiple Statuses</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 roster__grouping monthly__grouping"
      no-body
    >
      <div>
        <div class="group__top">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- <div class="demo-inline-spacing roster_group_dropdown">
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedRosterGroup"
                  variant="outline-primary"
                >
                  <b-dropdown-text style="width: 240px;">
                    Designated day off for roster groups
                  </b-dropdown-text>
                  <b-dropdown-item
                    v-for="(option, key) in rosterGroups"
                    :key="key"
                    @click="changeRosterGrouping(option.name)"
                  >
                    <div>
                      <span>{{ option.name }} ({{ option.dayOffDaysNumber }})</span>
                      <div class="text-nowrap">
                        <span
                          v-for="(day, index) in option.dayOffDays"
                          :key="index"
                        >
                          {{ day }} <span v-if="index + 1 != option.dayOffDays.length">, </span>
                        </span>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div> -->
              <!-- <div class="demo-inline-spacing">
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedStatusType"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    @click="changeStatusType(0)"
                  >
                    All
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(option, key) in statuses"
                    :key="key"
                    @click="changeStatusType(option._id)"
                  >
                    {{ option.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div> -->
              <div class="demo-inline-spacing">
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedYear"
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="(option, key) in yearValues"
                    :key="key"
                    @click="changeAnnualGrouping(option._id)"
                  >
                    {{ option.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="demo-inline-spacing">
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedMonth"
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="(option, key) in rosterMonths"
                    :key="key"
                    @click="changeRosterMonth(option._id)"
                  >
                    {{ option.name }} {{ option.rosterYear.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <div class="search_block d-flex align-items-center justify-content-end">
                  <a
                    href="javascript:void(0)"
                    class="nav-link nav-link-search"
                    @click="showSearchBar = !showSearchBar"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="21"
                      class="mr-50"
                    />
                    <span class="align-middle">Search</span>
                  </a>
                  <b-input-group
                    v-if="showSearchBar"
                    class="input-group-merge mr-50"
                  >
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Search..."
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="searchQuery = ''; showSearchBar = !showSearchBar"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <template>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                      /> More
                    </template>

                    <!-- <b-dropdown-item
                      v-if="canViewThisAction('create', 'RosterMonth')"
                      :to="{ name: 'operation-roster-annual-groupings-create', query: { clone: selectedId || 0 } }"
                    >
                      <span class="align-middle ml-50">Duplicate This Month</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      v-if="canViewThisAction('create', 'MonthlyRemark')"
                      id="toggle-btn"
                      v-b-modal.modal-create-remark
                      @click="cleanRemarksPreviousData"
                    >
                      <span class="align-middle ml-50">Add Remark to Day(s)</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="downloadPDF">
                      <span class="align-middle ml-50">Download PDF</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      v-if="canViewThisAction('delete', 'RosterMonth')"
                      @click="deleteEntity(selectedId || 0)"
                    >
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <div class="">
              <b-tabs
                pills
                class="booking__content"
              >
                <!-- Tab: Account -->
                <b-tab
                  active
                >
                  <template #title>
                    <span class="d-none d-sm-inline">Month</span>
                  </template>
                  <month-view
                    ref="monthComponent"
                    :annual-grouping-values="annualGroupingValues"
                    :roster-months="rosterMonths"
                    :monthly-grouping-values="monthlyGroupingValues"
                    :statuses="statuses"
                    :year-values="yearValues"
                    :selected-month="resolveMonthName()"
                    :roster-groups="rosterGroups"
                    :roster-remarks="rosterRemarks"
                    :monthly-grouping-statuses.sync="monthlyGroupingStatuses"
                    :teams="teams"
                    :search-query="searchQuery"
                    :selected-status-type-id="selectedStatusTypeId"
                    :selected-id="selectedId"
                    :status-updated-data="statusUpdatedData"
                    class=""
                    @emitDateValuesToPlot="dateValuesToPlot"
                  />
                </b-tab>

                <!-- Tab: Information -->
                <b-tab>
                  <template #title>
                    <span class="d-none d-sm-inline">Week</span>
                  </template>
                  <week-view
                    ref="weekComponent"
                    :annual-grouping-values="annualGroupingValues"
                    :roster-months="rosterMonths"
                    :monthly-grouping-values="monthlyGroupingValues"
                    :statuses="statuses"
                    :year-values="yearValues"
                    :selected-month="resolveMonthName()"
                    :roster-groups="rosterGroups"
                    :monthly-remarks="monthlyRemarks"
                    :monthly-grouping-statuses.sync="monthlyGroupingStatuses"
                    :teams="teams"
                    :search-query="searchQuery"
                    :selected-status-type-id="selectedStatusTypeId"
                    :selected-id="selectedId"
                    :status-updated-data="statusUpdatedData"
                    class=""
                    @emitDateValuesToPlot="dateValuesToPlot"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <div style="rotate:90; height: 0px; opacity: 0; font-family: 'Montserrat-Regular'">
      <roster-pdf
        id="pdfGenerateDiv"
        ref="pdfComponent"
        :annual-grouping-values="annualGroupingValues"
        :roster-months="rosterMonths"
        :monthly-grouping-values="monthlyGroupingValues"
        :statuses="statuses"
        :year-values="yearValues"
        :selected-month="resolveMonthName()"
        :roster-groups="rosterGroups"
        :roster-remarks="rosterRemarks"
        :monthly-grouping-statuses="monthlyGroupingStatuses"
        :teams="teams"
        :search-query="searchQuery"
        :selected-status-type-id="selectedStatusTypeId"
        :selected-id="selectedId"
        :status-updated-data="statusUpdatedData"
        class=""
      />
    </div> -->
    <!-- modal -->
    <b-modal
      id="modal-create-bulk-status"
      ref="create-bulk-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      size="xl"
      no-close-on-backdrop
      title="Update Multiple Statuses"
      @ok="submitMultipleStatusForm"
    >
      <b-form @submit.prevent="submitMultipleStatusForm">
        <validation-observer
          ref="createBulkStatusForm"
        >
          <b-row
            v-for="(item, index) in bulkStatus"
            :id="'serviceRequests' + index"
            :key="index"
            ref="row"
            class=""
          >
            <!-- Item Name -->
            <b-col cols="md-4">
              <validation-provider
                #default="{ errors }"
                name="user"
                rules="required"
              >
                <b-form-group
                  label=""
                  :label-for="'user' + index"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    :id="'user' + index"
                    v-model="item.user"
                    label="name"
                    placeholder="Select an option"
                    :options="userOptions"
                    :reduce="name => name._id"
                    :selectable="option => !option.hasOwnProperty('team')"
                    :clearable="false"
                  >
                    <template #option="{ team, name }">
                      <div
                        v-if="team"
                        class="group"
                      >
                        {{ team }}
                      </div>
                      {{ name }}
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Quantity -->
            <b-col cols="md-3">
              <b-form-group
                label=""
                :label-for="'date' + index"
              >
                <b-input-group>
                  <flat-pickr
                    :id="'date' + index"
                    v-model="item.date"
                    class="form-control flat-pickr-group"
                    placeholder=""
                    :config="flatPickrConfig"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="md-4">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group
                  label=""
                  :label-for="'status' + index"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    :id="'dstatus' + index"
                    v-model="item.status"
                    label="status"
                    placeholder="Select an option"
                    :options="statuses"
                    :reduce="name => name._id"
                    :clearable="false"
                  >
                    <template #option="{ name }">
                      {{ name }}
                    </template>
                    <template #selected-option="{ name }">
                      {{ name }}
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Remove Button -->
            <b-col
              class="remove__field"
              cols="md-1"
            >
              <b-button
                variant="flat-danger"
                class="mt-0 mt-md-2 primary-button-with-background no-background"
                type="button"
                @click="removeBulkUser(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-25"
                />
              </b-button>
            </b-col>
          </b-row>
          <div class="add_item">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addUserToLeave"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
                size="14"
              />
              <span class="align-middle">Add Another Staff</span>
            </b-button>
          </div>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-create-remark"
      ref="create-remark-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add Remark To Day(s)"
      @ok="submitRemarkForm"
    >
      <b-form
        class="createRemarkForm"
        @submit.prevent="submitRemarkForm"
      >
        <validation-observer
          ref="createRemarkForm"
        >
          <b-form-group
            label="Date(s)*"
            label-for="h-team-dates"
          >
            <validation-provider
              name="Date(s)"
              vid="dates"
              rules="required"
            >
              <b-form-tags
                v-model="dates"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="danger"
                        @remove="removeTag(tag)"
                      >
                        {{ resolveDateName(tag) }}
                      </b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                  >
                    <template v-slot:first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option
                        disabled
                        value=""
                      >
                        Choose a Date....
                      </option>
                    </template>
                    <option
                      v-for="(monthlyGrouping, ind) in monthlyGroupingValues"
                      :key="ind"
                      :value="monthlyGrouping.monthDate"
                    >
                      {{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'DD/MM/YYYY') }}
                    </option>
                  </b-form-select>
                </template>
              </b-form-tags>
            </validation-provider>
            <small
              v-if="dateValidation"
              class="text-danger"
            >
              {{ dateError }}
            </small>
          </b-form-group>
          <b-form-group
            label="Remark*"
            label-for="h-team-remark"
          >
            <validation-provider
              #default="{ errors }"
              name="Remark"
              vid="remark"
              rules=""
            >
              <b-form-input
                id="h-team-remark"
                v-model="remark"
                placeholder="Enter remark"
                name="remark"
                @input="remarkValidation == true ? remarkValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="remarkValidation"
                class="text-danger"
              >
                {{ remarkError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Color*"
            label-for="h-roster-status-type-color"
          >
            <validation-provider
              #default="{ errors }"
              name="Color"
              vid="color"
              rules="required"
            >
              <b-form-input
                id="h-roster-status-type-color"
                v-model="color"
                type="color"
                :state="(errors.length > 0 || colorValidation) ? false : null"
                name="color"
                @input="colorValidation == true ? colorValidation = false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="colorValidation"
                class="text-danger"
              >
                {{ colorError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BFormInput, BInputGroup, BInputGroupAppend, BTab, BTabs,
  BModal, BForm, BFormGroup, BFormTags, BFormTag, BFormSelect, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
// import html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import * as fontJs from '../../../assets/js/Montserrat-Regular-normal'
import WeekView from './WeekView.vue'
import MonthView from './MonthView.vue'
// import RosterPdf from './RosterPdf.vue'

const { canViewThisAction } = useAclUtils()
let currentUser = ''
let isRest = true

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTab,
    BTabs,
    BModal,
    BForm,
    BFormGroup,
    BFormTags,
    BFormTag,
    BFormSelect,
    BButton,

    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    WeekView,
    MonthView,
    // RosterPdf,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      showSearchBar: false,
      viewPDF: false,
      searchQuery: '',
      selectedMonth: 'Jan 0000',
      selectedYear: '0000',
      selectedId: '',
      selectedYearId: '',
      yearValues: [],
      statuses: [],
      annualGroupingValues: [],
      rosterMonths: [],
      monthlyGroupingValues: [],
      monthlyRemarks: [],
      teams: [],
      rosterGroups: [],
      relatedRecords: [],
      rosterRemarks: [],
      monthlyGroupingStatuses: [],
      dateArray: [],
      month: '',
      rosterYear: '',
      remark: '',
      remarkValidation: false,
      remarkError: 'Valid remark is required',
      dates: [],
      color: '#e2c5c5',
      colorValidation: false,
      colorError: 'Valid color is required',
      dateValidation: false,
      dateError: 'Valid date is required',
      monthValidation: false,
      monthError: 'Valid month is required',
      rosterYearValidation: false,
      rosterYearError: 'Valid year is required',
      selectedRosterGroup: 'Roster Groups',
      selectedStatusType: 'Status Types',
      selectedStatusTypeId: '',
      selectedMonthId: '',
      monthCreateError: '',
      monthCreateValidation: false,
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      bulkStatus: [{
        user: '',
        date: '',
        status: '',
      }],
      userOptions: [],
      statusUpdatedData: [],
      required,
    }
  },
  created() {
    this.$http.get('operation/roster-months/respond-with/extra-options')
      .then(response => {
        // this.teams = response.data.teams || []
        this.rosterGroups = response.data.rosterGroups || []
        this.statuses = response.data.statuses || []
        // this.teams.forEach(element => {
        //   this.userOptions.push({
        //     team: element.name,
        //     name: null,
        //
        //     _id: element._id,
        //   })
        //   element.users.forEach(user => {
        //
        //     const existingUser = this.userOptions.find(o => o._id === user._id)
        //     if (!existingUser) {
        //       this.userOptions.push({
        //         name: user.name,
        //
        //         _id: user._id,
        //       })
        //     }
        //   })
        // })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('operation/roster-months',
      {
        params: {
          // search: searchQuery.value,
          sortBy: 'name',
          sortDesc: false,
        },
      })
      .then(response => {
        this.yearValues = response.data.yearValues || []
        this.annualGroupingValues = response.data.annualGroupingValues || []
        this.rosterMonths = response.data.rosterMonths || []
        this.monthlyGroupingValues = response.data.monthlyGroupingValues || []
        this.monthlyRemarks = response.data.monthlyRemarks || []
        // this.monthlyGroupingStatuses = response.data.monthlyGroupingStatuses || []
        const timezone = process.env.VUE_APP_TIME_ZONE

        if (this.yearValues.length) {
          // eslint-disable-next-line eqeqeq
          let currentYear = this.yearValues.find(o => o.name == moment().utcOffset(timezone).year())
          if (!currentYear) {
            // eslint-disable-next-line prefer-destructuring
            currentYear = this.yearValues[0]
          }
          this.selectedYear = currentYear.name

          this.selectedYearId = currentYear._id
        }

        if (this.rosterMonths.length) {
          let currentMonth = this.rosterMonths.find(o => moment(o.monthDate).utcOffset(timezone).isSame(moment().startOf('month').utcOffset(timezone), 'day'))
          if (!currentMonth) {
            // eslint-disable-next-line prefer-destructuring
            currentMonth = this.rosterMonths[0]
          }
          this.defineMonthDates(currentMonth)
          this.selectedMonth = `${currentMonth.name} ${currentMonth.rosterYear.name}`

          this.selectedId = currentMonth._id

          // this.teams.sort((a, b) => currentMonth.rosterYear.teamPriority.indexOf(a._id) - currentMonth.rosterYear.teamPriority.indexOf(b._id))
          this.calculateRosterRemarks()

          this.getStatusValues(currentMonth._id)
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRosterGrouping(group) {
      this.selectedRosterGroup = group
    },
    cleanRemarksPreviousData() {
      this.dates = []
      this.remark = ''
    },
    changeStatusType(id) {
      const type = this.statuses.find(o => o._id === id)
      if (type) {
        this.selectedStatusType = type.name
        this.selectedStatusTypeId = id
      } else {
        this.selectedStatusTypeId = ''
        this.selectedStatusType = 'All'
      }
    },
    addUserToLeave() {
      this.bulkStatus.push({
        user: '',
        date: '',
        status: '',
      })
    },
    updateFlatPickrConfig() {
      const timezone = process.env.VUE_APP_TIME_ZONE

      const currentMonth = this.rosterMonths.find(o => o._id === this.selectedId)
      const currentMoment = moment(`01-${currentMonth.name}-${currentMonth.rosterYear.name}`, 'DD-MMM-YYYY').utcOffset(timezone)
      const endMoment = moment(currentMoment).endOf('month').utcOffset(timezone)
      this.flatPickrConfig = {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: endMoment.format('DD/MM/YYYY'), minDate: currentMoment.format('DD/MM/YYYY'),
      }
    },
    removeBulkUser(index) {
      if (this.bulkStatus.length > 1) {
        this.bulkStatus.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one row.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    defineMonthDates(currentMonth) {
      this.monthlyGroupingValues = []
      const timezone = process.env.VUE_APP_TIME_ZONE
      const currentMoment = moment(`01-${currentMonth.name}-${currentMonth.rosterYear.name}`, 'DD-MMM-YYYY').utcOffset(timezone)
      const endMoment = moment(currentMoment).endOf('month').utcOffset(timezone)
      while (currentMoment.isSameOrBefore(endMoment)) {
        const dateObj = {}

        dateObj.rosterMonth = currentMonth._id
        dateObj.monthDate = currentMoment.format('YYYY-MM-DD')
        dateObj.month = currentMoment.format('MMM')
        dateObj.day = currentMoment.format('ddd')
        this.monthlyGroupingValues.push(dateObj)

        currentMoment.add(1, 'days')
      }
    },
    resolveDateName(id) {
      const month = this.monthlyGroupingValues.find(o => o.monthDate === id)
      if (month) {
        return moment(month.monthDate).format('DD/MM/YYYY')
      }
      return ''
    },
    formatMonthlyGroupingDate(date, format) {
      return moment(date).format(format)
    },
    resolveGroupStatus(user, month, day) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month.toLowerCase()])
        if (val) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < val.dayOffDays.length; i++) {
            // eslint-disable-next-line no-plusplus
            if (val.dayOffDays[i] === day) {
              return val._id
            }
          }
        }
      }

      return null
    },
    downloadPDF() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operation/download/roster/${this.selectedId}`)
      formData.append('landscape', true)
      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Monthly Roster ${this.selectedMonth}.pdf`
          link.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    oldDownloadPDF() {
      // start of pdf using html2PDF package
      // const element = document.getElementById('pdfGenerateDiv')
      // const opt = {
      //   margin: 1,
      //   filename: 'roster.pdf',
      //   pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      //   image: { type: 'jpeg', quality: 1 },
      //   html2canvas: { scale: 2, windowWidth: 1024 },
      //   jsPDF: { unit: 'pt', format: 'a4', orientation: 'l' },
      // }

      // html2pdf().set(opt).from(element).save()
      // end of pdf using html2PDF package

      // start of pdf using jspdf only
      // eslint-disable-next-line no-unused-expressions
      fontJs
      const vm = this
      const element = document.getElementById('pdfGenerateDiv')
      element.style.fontFamily = 'Montserrat-Regular'
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('l', 'pt', 'a4')
      // console.log(pdf.getFontList())
      pdf.setFont('Montserrat-Regular')
      pdf.setProperties({
        title: `Monthly Roster ${vm.selectedMonth}`,
      })
      pdf.html(element, {
        // margin: [40, 60, 40, 60],
        width: 842,
        windowWidth: 842,
        callback(pdfr) {
          pdfr.save(`Monthly Roster ${vm.selectedMonth}.pdf`)
        },
        // fontFaces: [{
        //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
        // }],
      })
      // end of pdf using jspdf only
    },
    submitRemarkForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      const timezone = process.env.VUE_APP_TIME_ZONE
      this.$refs.createRemarkForm.validate().then(success => {
        if (success) {
          const remarksDates = []
          this.monthlyRemarks.forEach(value => {
            value.applicableDates.forEach(date => {
              remarksDates.push(moment(date).utcOffset(timezone).format('YYYY-MM-DD'))
            })
          })

          const existingRemarks = []
          this.dates.forEach(date => {
            if (remarksDates.includes(date)) {
              const foundRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(date), 'day')))
              if (foundRemark.message) {
                existingRemarks.push(moment(date).utcOffset(timezone).format('DD/MM/YYYY'))
              }
            }
          })

          if (existingRemarks.length) {
            this.$swal({
              title: 'Overwrite - Are you sure?',
              html: `There is remark on ${existingRemarks.toString()} already. Are you sure you want to overwrite?`,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/warning.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonText: 'Yes, I’m Sure',
              cancelButtonText: 'No, Go Back',
              customClass: {
                confirmButton: 'btn btn-danger ml-1',
                cancelButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  this.submitFinalRemarksData('update')
                }
              })
          } else {
            this.submitFinalRemarksData('new')
          }
        } else {
          // if (this.remark === '') {
          //   this.remarkError = 'Remark is Required'
          //   this.remarkValidation = true
          // } else {
          //   this.remarkValidation = false
          // }
          if (this.color === '') {
            this.colorError = 'Color is Required'
            this.colorValidation = true
          } else {
            this.colorValidation = false
          }
          if (this.dates.length === 0) {
            this.dateError = 'Date is Required'
            this.dateValidation = true
          } else {
            this.dateValidation = false
          }
          // this.$refs['create-remark-modal'].toggle('#toggle-btn')
        }
      })
    },
    submitFinalRemarksData(type) {
      const formData = new FormData()
      formData.append('applicableDates', JSON.stringify(this.dates))
      formData.append('message', this.remark)
      formData.append('color', this.color)
      this.$http.patch(`operation/roster-months/${this.selectedId}/remarks`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$refs['create-remark-modal'].toggle('#toggle-btn')
          this.monthlyRemarks = response.data.monthlyRemarks || []
          this.calculateRosterRemarks()
          const formatedDates = []
          const timezone = process.env.VUE_APP_TIME_ZONE
          this.dates.forEach(date => {
            formatedDates.push(moment(date).utcOffset(timezone).format('DD-MMM-YYYY'))
          })
          this.$swal({
            title: `Roster Remark ${type === 'new' ? 'Created' : 'Updated'}!`,
            html: `The Roster Remark for <strong>${formatedDates.toString()}</strong> has been ${type === 'new' ? 'created' : 'updated'}`,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/save.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          // .then(result => {
          //   if (result.value) {
          //
          //     this.changeRosterMonth(response.data.data._id)
          //   }
          //   this.remark = ''
          //   this.dates = []
          //   this.color = ''
          // })
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'message') {
                this.remarkError = validationError.msg
                this.remarkValidation = true
              } else if (validationError.param === 'color') {
                this.colorError = validationError.msg
                this.colorValidation = true
              } else if (validationError.param === 'applicableDates') {
                this.dateError = validationError.msg
                this.dateValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    submitMultipleStatusForm() {
      this.$refs.createBulkStatusForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('rosterMonth', this.selectedId)
          formData.append('bulkStatus', JSON.stringify(this.bulkStatus))
          this.$http.patch('operation/roster-months/update/bulk/status-type', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.bulkStatus = [{
                user: '',
                date: '',
                status: '',
              }]
              this.monthlyGroupingStatuses = response.data.statuses
              this.dateValuesToPlot()
              this.$swal({
                title: 'Roster Status Updated!',
                html: response.data.message,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              // .then(result => {
              //   if (result.value) {
              //     if (this.$refs.monthComponent) {
              //       this.$refs.monthComponent.changeMonthlyGrouping(this.selectedId)
              //     }
              //     if (this.$refs.weekComponent) {
              //       this.$refs.weekComponent.changeMonthlyGrouping(this.selectedId)
              //     }
              //   }
              //   this.bulkStatus = [{
              //     user: '',
              //     date: '',
              //     status: '',
              //   }]
              // })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.monthError = validationError.msg
                    this.monthValidation = true
                  } else if (validationError.param === 'year') {
                    this.rosterYearError = validationError.msg
                    this.rosterYearValidation = true
                  }
                })
                // this.monthCreateValidation = true
                // this.monthCreateError = `A Monthly Roster has been created for ${this.month}. Please choose a different month.`
                this.$refs['create-bulk-status-modal'].show()

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please Fill Out Each Field',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          // if (this.month === '') {
          //   this.monthError = 'Month is Required'
          //   this.monthValidation = true
          // } else {
          //   this.monthValidation = false
          // }
          // if (this.rosterYear === '') {
          //   this.rosterYearError = 'Year is Required'
          //   this.rosterYearValidation = true
          // } else {
          //   this.rosterYearValidation = false
          // }
          this.$refs['create-bulk-status-modal'].toggle('#toggle-btn')
        }
      })
    },

    changeAnnualGrouping(year) {
      const currentYear = this.yearValues.find(o => o._id === year)
      if (currentYear) {
        this.selectedYear = currentYear.name
      }
      this.selectedYearId = year
      this.changeRosterMonth()
    },
    changeRosterMonth(month = null) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      this.$http.get('operation/roster-months',
        {
          params: {
            // search: searchQuery.value,
            selectedMonth: month,
            selectedYear: this.selectedYearId,
            sortBy: 'name',
            sortDesc: false,
          },
        })
        .then(response => {
          this.annualGroupingValues = response.data.annualGroupingValues || []
          this.rosterMonths = response.data.rosterMonths || []
          this.monthlyGroupingValues = response.data.monthlyGroupingValues || []
          this.monthlyRemarks = response.data.monthlyRemarks || []
          this.monthlyGroupingStatuses = response.data.monthlyGroupingStatuses || []
          if (!month) {
            if (this.rosterMonths.length) {
              let currentMonth = this.rosterMonths.find(o => moment(o.monthDate).utcOffset(timezone).isSame(moment().startOf('month').utcOffset(timezone), 'day'))
              if (!currentMonth) {
                // eslint-disable-next-line prefer-destructuring
                currentMonth = this.rosterMonths[0]
              }

              this.selectedId = currentMonth._id
            }
          } else {
            this.selectedId = month
          }

          const rosterMonth = this.rosterMonths.find(o => o._id === this.selectedId)
          this.defineMonthDates(rosterMonth)

          this.selectedId = rosterMonth._id
          this.selectedMonth = `${rosterMonth.name} ${rosterMonth.rosterYear.name}`

          // this.teams.sort((a, b) => rosterMonth.rosterYear.teamPriority.indexOf(a._id) - rosterMonth.rosterYear.teamPriority.indexOf(b._id))
          this.calculateRosterRemarks()

          this.getStatusValues(this.selectedId)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getStatusValues(month = null) {
      this.$http.get('operation/roster-months/get-data/status',
        {
          params: {
            selectedMonth: month,
          },
        })
        .then(response => {
          this.monthlyGroupingStatuses = response.data.monthlyGroupingStatuses || []
          this.teams = response.data.teams || []

          const currentMonth = this.rosterMonths.find(o => o._id === month)
          if (currentMonth && currentMonth.rosterYear && currentMonth.rosterYear.teamPriority && currentMonth.rosterYear.teamPriority.length) {
            // console.log(currentMonth)

            this.teams.sort((a, b) => currentMonth.rosterYear.teamPriority.indexOf(a._id) - currentMonth.rosterYear.teamPriority.indexOf(b._id))
          }
          this.dateValuesToPlot()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    dateValuesToPlot() {
      this.statusUpdatedData = []
      const totalArray = []
      this.userOptions = []
      this.teams.forEach((element, key) => {
        this.userOptions.push({
          team: element.name,
          name: null,

          _id: element._id,
        })
        const userArray = []
        element.users.forEach((user, index) => {
          const existingUser = this.userOptions.find(o => o._id === user._id)
          if (!existingUser) {
            this.userOptions.push({
              name: user.name,

              _id: user._id,
            })
          }
          const dataArray = []
          this.monthlyGroupingValues.forEach(monthlyGrouping => {
            dataArray.push({ data: this.resolveDateValue(key, index, monthlyGrouping), date: monthlyGrouping.monthDate })
          })
          userArray.push(dataArray)
        })
        totalArray.push(userArray)
      })
      this.statusUpdatedData = totalArray
    },
    resolveDateValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      let user = team.users[userIndex]
      let state = ''
      let color = ''
      let shouldChangeState = true
      user = JSON.parse(JSON.stringify(user))

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.monthlyGroupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status._id === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id)
        if (val) {
          state = val.status.abbreviation
          color = val.status.color
          shouldChangeState = false
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) {
              if (currentUser === '') {
                isRest = false
                if (shouldChangeState) {
                  state = 'OFF'
                  color = '#F5BC16'
                }
              // eslint-disable-next-line no-else-return, no-underscore-dangle
              } else {
                // eslint-disable-next-line no-lonely-if, no-underscore-dangle
                if (currentUser === user._id) {
                  if (isRest === false) {
                    isRest = true
                    if (shouldChangeState) {
                      state = 'REST'
                      color = '#F5BC16'
                    }
                  // eslint-disable-next-line no-else-return
                  } else {
                    isRest = false
                    if (shouldChangeState) {
                      state = 'OFF'
                      color = '#F5BC16'
                    }
                  }
                } else {
                  isRest = false
                  if (shouldChangeState) {
                    state = 'OFF'
                    color = '#F5BC16'
                  }
                }
              }

              currentUser = user._id
            }
          }
        }
      }
      return { state, color }
    },
    calculateRosterRemarks() {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const remarksArray = []
      let includeConsecutiveCount = 1
      let excludeConsecutiveCount = 1
      const remarksDates = []
      this.monthlyRemarks.forEach(value => {
        value.applicableDates.forEach(date => {
          remarksDates.push(moment(date).utcOffset(timezone).format('YYYY-MM-DD'))
        })
      })
      this.monthlyGroupingValues.forEach((obj, key) => {
        if (remarksDates.includes(obj.monthDate)) {
          const foundRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(obj.monthDate), 'day')))
          this.monthlyGroupingValues[key] = { ...this.monthlyGroupingValues[key], color: foundRemark.message ? foundRemark.color : '' }
          if (key < (this.monthlyGroupingValues.length - 1) && remarksDates.includes(this.monthlyGroupingValues[key + 1].monthDate)) {
            const nextRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(this.monthlyGroupingValues[key + 1].monthDate), 'day')))

            if (foundRemark._id === nextRemark._id) {
              includeConsecutiveCount += 1
            } else {
              const thObj = {}
              if (foundRemark.message) {
                thObj.message = foundRemark.message
                thObj.color = foundRemark.color
              } else {
                thObj.message = ''
                thObj.color = ''
              }
              thObj.colspan = includeConsecutiveCount
              remarksArray.push(thObj)

              includeConsecutiveCount = 1
            }
          } else {
            const thObj = {}
            if (foundRemark.message) {
              thObj.message = foundRemark.message
              thObj.color = foundRemark.color
            } else {
              thObj.message = ''
              thObj.color = ''
            }
            thObj.colspan = includeConsecutiveCount
            remarksArray.push(thObj)

            includeConsecutiveCount = 1
          }
        } else {
          this.monthlyGroupingValues[key] = { ...this.monthlyGroupingValues[key], color: '' }
          // eslint-disable-next-line no-lonely-if
          if (key < (this.monthlyGroupingValues.length - 1) && remarksDates.includes(this.monthlyGroupingValues[key + 1].monthDate)) {
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else if (key === (this.monthlyGroupingValues.length - 1)) {
            // excludeConsecutiveCount += 1
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else {
            excludeConsecutiveCount += 1
          }
        }
      })
      this.rosterRemarks = remarksArray
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: `Deleting <strong>will erase all the information</strong> for ${this.selectedMonth}. This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(`operation/roster-months/${id}/delete`)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message ?? '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.changeRosterMonth(response.data.monthID)
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    // eslint-disable-next-line no-unused-vars
    resolveGroupName(user, month) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month])
        if (val) {
          return val.abbreviation
        }
      }

      return 'N/A'
    },

    resolveMonthID() {
      const month = this.rosterMonths.find(o => o._id === this.selectedId)
      if (month) {
        return month._id
      }

      return '0'
    },

    resolveYearID() {
      const month = this.rosterMonths.find(o => o._id === this.selectedId)
      if (month) {
        return month.rosterYear
      }

      return '0'
    },
    resolveMonthName() {
      const month = this.rosterMonths.find(o => o._id === this.selectedId)
      if (month) {
        return `${month.name} ${month.rosterYear.name}`
      }

      return '0'
    },
  },
}
</script>

<style scoped>
  table td.no-padding-td {
    padding: 12px 0px !important
  }
  table td.td-no-padding-right {
    padding: 12px 8px !important
  }
  .white-body-background {
    background-color: white;
  }
</style>
