<template>

  <div>
    <div class="">
      <b-table-simple
        ref="teamsTable"
        class="position-relative has_padding"
        responsive
        hover
        caption-top
        sticky-header="500px"
      >
        <b-thead head-variant="light">
          <b-tr class="caption_block">
            <th
              sticky-column
              colspan="4"
              style="left: 0; z-index: 99"
            >
              <caption style="border-radius: 5px 0 0 0;">
                Monthly Roster {{ selectedMonth }}
              </caption>
            </th>

            <th
              :colspan="monthlyGroupingValues.length ? monthlyGroupingValues.length - 3 : 1"
            >
              <caption
                style="border-radius: 0 5px 0 0; padding: 19px 0;
              border-radius: 0 5px 0 0;"
              >
                &nbsp;
              </caption>
            </th>
          </b-tr>
          <b-tr class="group__tag">
            <b-th />
            <b-th
              v-for="(remark, index) in rosterRemarks"
              :key="index"
              class="group__tag_info text-center"
              :colspan="remark.colspan"
              :style="{ 'background': remark.color + '!important' }"
            >
              <span>{{ remark.message }}</span>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              sticky-column
              style="left: 0; z-index: 99"
            >
              Staff Name
            </b-th>
            <b-th
              v-for="(monthlyGrouping, index) in monthlyGroupingValues"
              :key="index"
              class=""
            >
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'ddd') }} {{ formatLocalMonthlyGroupingDate(monthlyGrouping.monthDate) }}</span>
              <br>
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'D-MMM') }}</span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          v-for="(team, key) in searchUser"
          :key="key"
        >
          <b-tr
            v-if="team.users.length"
            class="team-tr"
          >
            <b-td
              colspan="6"
              sticky-column
            >
              {{ team.name }}
            </b-td>
            <b-td
              :colspan="monthlyGroupingValues.length ? (monthlyGroupingValues.length - 5) : 0"
              class="text-right"
            >
              <div class="action_button">
                <b-button
                  v-show="key != 0"
                  variant="flat-primary"
                  @click="moveTeam(key, key-1)"
                >
                  <feather-icon
                    icon="ArrowUpIcon"
                    class="mr-50"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-show="key != (teams.length - 1)"
                  variant="flat-primary"
                  @click="moveTeam(key, key+1)"
                >
                  <feather-icon
                    icon="ArrowDownIcon"
                    class="mr-50"
                    size="16"
                  />
                </b-button>
              </div>
            </b-td>
          </b-tr>
          <b-tr
            v-for="(user, index) in team.users"
            :key="index"
            class="user-tr"
          >
            <b-td sticky-column>
              {{ user.name }}
            </b-td>
            <!-- <b-td
            v-for="(monthlyGrouping, dateIndex) in monthlyGroupingValues"
            :key="dateIndex"
            class="td-no-padding-right"
            :style="{ 'background-color': resolveDateBGValue(key, index, monthlyGrouping) }"
            @dblclick="setDoubleClickEdit(key, index, dateIndex)"
          >
            <span>{{ resolveDateValue(key, index, monthlyGrouping) }}</span>
            <b-button
              v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (doubleClickEnable[key] ? (doubleClickEnable[key][index] ? (doubleClickEnable[key][index][dateIndex] ? doubleClickEnable[key][index][dateIndex] : false ) : false ) : false)"
              variant="flat-primary"
              @click="enableEditing(monthlyGrouping, user._id, team._id)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </b-td> -->

            <b-td
              v-for="(monthlyGrouping, dateIndex) in monthlyGroupingValues"
              :key="dateIndex"
              class="td-no-padding-right hover-td"
              :style="{ 'background-color': statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? statusUpdatedData[key][index][dateIndex].data.color : '') : '' }"
            >
              <div class="">
                <span>{{ statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? statusUpdatedData[key][index][dateIndex].data.state : '') : '' }}</span>
                <b-button
                  v-if="canViewThisAction('create', 'MonthlyGroupingStatus')"
                  variant="flat-primary"
                  class="edit-icon no-padding-icon-button"
                  @click="enableEditing(monthlyGrouping, user._id, team._id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class=""
                    size="14"
                  />
                </b-button>
                <b-button
                  v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (!['', 'OFF', 'REST'].includes(statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? statusUpdatedData[key][index][dateIndex].data.state : '') : ''))"
                  variant="flat-danger"
                  class="edit-icon no-padding-icon-button"
                  @click="deleteStatusValue(monthlyGrouping, user._id, team._id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class=""
                    size="14"
                  />
                </b-button>
                <b-button
                  v-if="canViewThisAction('create', 'MonthlyGroupingStatus') && (['OFF', 'REST'].includes(statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? statusUpdatedData[key][index][dateIndex].data.state : '') : ''))"
                  variant="flat-danger"
                  class="edit-icon no-padding-icon-button"
                  @click="deleteOffStatusValue(monthlyGrouping, user._id, team._id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class=""
                    size="14"
                  />
                </b-button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- modal -->
    <b-modal
      id="modal-update-team"
      ref="update-team-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add Status Type"
      @ok="submitStatusForm"
    >
      <b-form @submit.prevent="submitStatusForm">
        <validation-observer
          ref="statusForm"
        >
          <b-form-group
            label="Status Type*"
            label-for="h-status-type"
          >
            <validation-provider
              name="Status Type"
              vid="statusType"
              rules="required"
            >
              <!-- <b-form-select
                :value="statusType"
                @change.native="statusType = $event.target.value"
              >
                <template v-slot:first>
                  <option
                    disabled
                    value=""
                  >
                    Choose a Status....
                  </option>
                </template>
                <option
                  v-for="(type, ind) in statuses"
                  :key="ind"
                  :value="type._id"
                >
                  {{ type.name }}
                </option>
              </b-form-select> -->
              <select
                id="h-status-type"
                class="form-control"
                @change="setStatusValue"
              >
                <option
                  disabled
                  value=""
                  selected
                >
                  Choose a Status....
                </option>
                <option
                  v-for="(type, ind) in statuses"
                  :key="ind"
                  :value="type._id"
                >
                  {{ type.name }}
                </option>
              </select>
            </validation-provider>
            <small
              v-if="statusTypeValidation"
              class="text-danger"
            >
              {{ statusTypeError }}
            </small>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import moment from 'moment'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()
let currentUser = ''
let isRest = true

export default {
  components: {
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BModal,
    BForm,
    BFormGroup,
    // BFormSelect,

    // vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    annualGroupingValues: {
      type: Array,
      required: true,
    },
    rosterMonths: {
      type: Array,
      required: true,
    },
    monthlyGroupingValues: {
      type: Array,
      required: true,
    },
    statusUpdatedData: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    yearValues: {
      type: Array,
      required: true,
    },
    rosterGroups: {
      type: Array,
      required: true,
    },
    monthlyGroupingStatuses: {
      type: Array,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
    rosterRemarks: {
      type: Array,
      required: true,
    },
    selectedMonth: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    selectedStatusTypeId: {
      type: String,
      default: '',
    },
    selectedId: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      canViewThisAction,
      statusType: '',
      selectedMonthlyGrouping: '',
      selectedUserId: '',
      selectedTeamId: '',
      monthlyStatuses: [],
      doubleClickEnable: [],
      required,
      statusTypeValidation: false,
      statusTypeError: '',
    }
  },
  computed: {
    groupingStatuses() {
      if (this.monthlyStatuses.length) {
        return this.monthlyStatuses
      }
      return this.monthlyGroupingStatuses
    },
    searchUser() {
      return this.teams.map(team => {
        const data = { ...team }
        data.users = data.users.filter(user => user.name.toLowerCase().match(this.searchQuery.toLowerCase()))
        return data
      })
    },
  },
  // updated() {
  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 0; i < this.teams.length; i++) {
  //     const teamArray = []
  //     // eslint-disable-next-line no-plusplus
  //     for (let j = 0; j < this.teams[i].users.length; j++) {
  //       const userArray = []
  //       // eslint-disable-next-line no-plusplus
  //       for (let k = 0; k < this.monthlyGroupingValues.length; k++) {
  //         userArray[k] = false
  //       }
  //       teamArray[j] = userArray
  //     }
  //     this.doubleClickEnable[i] = teamArray
  //   }
  //   // console.log(this.doubleClickEnable[0])
  // },
  created() {
    if (this.selectedId) {
      this.changeMonthlyGrouping(this.selectedId)
    }
  },
  methods: {
    setStatusValue(event) {
      this.statusType = event.target.value
    },
    formatMonthlyGroupingDate(date, format) {
      return moment(date).format(format)
    },
    formatLocalMonthlyGroupingDate(date) {
      return moment(date).locale('zh_cn').format('dd')
    },

    // eslint-disable-next-line no-unused-vars
    resolveGroupName(user, month) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month])
        if (val) {
          return val.abbreviation
        }
      }

      return 'N/A'
    },

    resolveMonthID() {
      const month = this.rosterMonths.find(o => o.name === this.selectedMonth)
      if (month) {
        return month._id
      }

      return '0'
    },

    resolveGroupStatus(user, month, day) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month.toLowerCase()])
        if (val) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < val.dayOffDays.length; i++) {
            // eslint-disable-next-line no-plusplus
            if (val.dayOffDays[i] === day) {
              return val._id
            }
          }
        }
      }

      return null
    },
    changeMonthlyGrouping(month) {
      this.$http.get(`operation/monthly-grouping/${month}/status-type`)
        .then(response => {
          // this.monthlyStatuses = response.data.statuses || []
          this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
          this.$emit('emitDateValuesToPlot')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    resolveDateValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      let user = team.users[userIndex]
      let state = ''
      let shouldChangeState = true
      user = JSON.parse(JSON.stringify(user))

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            state = status.abbreviation
            shouldChangeState = false
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) {
              if (currentUser === '') {
                isRest = false
                if (shouldChangeState) {
                  state = 'OFF'
                }
              // eslint-disable-next-line no-else-return, no-underscore-dangle
              } else {
                // eslint-disable-next-line no-lonely-if, no-underscore-dangle
                if (currentUser === user._id) {
                  if (isRest === false) {
                    isRest = true
                    if (shouldChangeState) {
                      state = 'REST'
                    }
                  // eslint-disable-next-line no-else-return
                  } else {
                    isRest = false
                    if (shouldChangeState) {
                      state = 'OFF'
                    }
                  }
                } else {
                  isRest = false
                  if (shouldChangeState) {
                    state = 'OFF'
                  }
                }
              }

              currentUser = user._id
            }
          }
        }
      }
      return state
    },
    resolveDateValueAgain(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      const user = team.users[userIndex]

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            return status.abbreviation
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) return 'OFF'
          }
        }
      }
      return ''
    },
    // setDoubleClickEdit(teamIndex, userIndex, dateIndex) {
    //   // this.$set(this.doubleClickEnable[teamIndex][userIndex], dateIndex, true)
    //   this.doubleClickEnable[teamIndex][userIndex].splice(dateIndex, 1, true)
    //   this.doubleClickEnable[teamIndex].splice(userIndex, 1, this.doubleClickEnable[teamIndex][userIndex])
    //   this.doubleClickEnable.splice(teamIndex, 1, this.doubleClickEnable[teamIndex])
    // },

    resolveDateBGValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      const user = team.users[userIndex]

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        let grpStatuses = this.groupingStatuses
        if (this.selectedStatusTypeId) {
          grpStatuses = this.groupingStatuses.filter(val => val.status === this.selectedStatusTypeId)
        }

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id)
        if (val) {
          const status = this.statuses.find(o => o._id === val.status)
          if (status) {
            return status.color
          }
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) return '#F5BC16'
          }
        }
      }
      return ''
    },

    enableEditing(monthlyGrouping, userId, teamId) {
      this.selectedMonthlyGrouping = monthlyGrouping
      this.selectedUserId = userId
      this.selectedTeamId = teamId
      this.$refs['update-team-modal'].show()
    },

    deleteStatusValue(monthlyGrouping, userId, teamId) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'This will remove the status from this date.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('user', userId)
            formData.append('team', teamId)
            formData.append('rosterMonth', monthlyGrouping.rosterMonth)
            formData.append('date', monthlyGrouping.monthDate)
            this.$http.patch('operation/roster-months/remove/status-type', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                // this.monthlyStatuses = response.data.statuses || []
                this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
                this.$emit('emitDateValuesToPlot')
                this.remark = ''
                this.dates = []
                this.color = ''
                this.$swal({
                  title: 'Roster Status Removed!',
                  html: 'The Roster Status has been removed',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    deleteOffStatusValue(monthlyGrouping, userId, teamId) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'This will remove the off/rest from this date.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('user', userId)
            formData.append('team', teamId)
            formData.append('rosterMonth', monthlyGrouping.rosterMonth)
            formData.append('date', monthlyGrouping.monthDate)
            this.$http.patch('operation/roster-months/remove/group-off-day', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                // this.monthlyStatuses = response.data.statuses || []
                this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
                this.$emit('emitDateValuesToPlot')
                this.remark = ''
                this.dates = []
                this.color = ''
                this.$swal({
                  title: 'Roster Off Status Removed!',
                  html: 'The Roster Status has been removed',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    submitStatusForm() {
      this.$refs.statusForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.statusType)
          formData.append('user', this.selectedUserId)
          formData.append('team', this.selectedTeamId)
          formData.append('rosterMonth', this.selectedMonthlyGrouping.rosterMonth)
          formData.append('date', this.selectedMonthlyGrouping.monthDate)
          this.$http.patch('operation/roster-months/update/status-type', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              // this.monthlyStatuses = response.data.statuses || []
              this.$emit('update:monthlyGroupingStatuses', response.data.statuses)
              this.$emit('emitDateValuesToPlot')
              this.remark = ''
              this.dates = []
              this.color = ''
              this.$swal({
                title: 'Roster Status Updated!',
                html: response.data.message,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusTypeError = validationError.msg
                    this.statusTypeValidation = true
                  }
                })

                this.$refs['update-team-modal'].toggle('#toggle-btn')

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          if (this.statusType === '') {
            this.statusTypeError = 'Status Type is Required'
            this.statusTypeValidation = true
          } else {
            this.statusTypeValidation = false
          }
          this.$refs['update-team-modal'].toggle('#toggle-btn')
        }
      })
    },

    moveTeam(from, to) {
      this.teams.move(from, to)

      const teamPriorityArray = []
      this.teams.forEach(team => {
        teamPriorityArray.push(team._id)
      })
      const formData = new FormData()

      formData.append('rosterMonth', this.selectedId)
      formData.append('teamPriority', JSON.stringify(teamPriorityArray))

      this.$http.patch('operation/roster-months/update/team-priority', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  table td.no-padding-td {
    padding: 12px 0px !important
  }
  table td.td-no-padding-right {
    padding: 12px 8px !important
  }
  .white-body-background {
    background-color: white;
  }
  .v-select {
    width: 95px;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > th {
      position: sticky;
      top: 38px;
      z-index: 5;
  }
  .b-table-sticky-header > .table.b-table > thead > tr:last-child > th{
    position: sticky;
      top: 103px;
      z-index: 5;
  }
  .roster__grouping.monthly__grouping .table.b-table.b-table-caption-top > caption {
      position: sticky;
      top: 0;
      z-index: 5;
  }
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column{
    left:0;
    z-index: 6!important;
  }
  .roster__grouping.monthly__grouping .no-padding-td .table-responsive > table > tbody > tr > td.b-table-sticky-column{
    left:0;
    z-index: 3!important;
    position: sticky;
  }
  .team-tr td {
    background: #d2dcea!important;
    border: none!important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 30px !important;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .user-tr td {
    font-weight: 600;
    min-width: 230px!important;
    max-width: 230px!important;
    padding-left: 30px !important;
  }
  .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
    background-color: #fff;
  }
</style>
